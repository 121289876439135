<template>
  <base-section
    id="lavvira-demo-and-contact"
  >
    <v-responsive
      class="mx-auto"
      max-width="1200"
    >
      <v-container fluid>
        <v-card
          width="100%"
          flat
          class="mb-6"
        >
          <v-card-title
            primary-title
            class="justify-center"
          >
            <div>
              <h2>We look forward to hearing from you!</h2>
            </div>
          </v-card-title>
        </v-card>
        <v-card flat>
          <v-tabs
            :vertical="$vuetify.breakpoint.mdAndUp"
            @change="reset"
          >
            <v-tab class="font-weight-bold">
              Request a demo
            </v-tab>
            <v-tab class="font-weight-bold">
              Send us a message
            </v-tab>

            <v-tab-item>
              <v-card
                flat
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-card-text class="mt-n1">
                      <p style="font-size: 18px;">
                        Have we provoked curiosity?
                      </p>

                      <p style="font-size: 18px;">
                        Let us show you the extraordinary possibilities of lavvira.
                        See how easy it can be to save time when processing your cases and documents.
                      </p>

                      <p style="font-size: 18px;">
                        Just choose a date that suits you and the lavvira experts will show you everything you want to see.
                      </p>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <div
                      v-if="!picker"
                      :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
                    >
                      <v-date-picker
                        v-model="picker"
                        elevation="15"
                        class="mb-8"
                        :header-color="`secondary`"
                      />
                    </div>
                    <div v-else>
                      <v-card
                        class="mt-2 mb-8 mx-8"
                        elevation="15"
                      >
                        <v-card-text>
                          <form class="mt-4">
                            <v-text-field
                              v-model="firstName"
                              outlined
                              :error-messages="firstNameErrors"
                              label="First Name"
                              required
                              @input="$v.firstName.$touch()"
                              @blur="$v.firstName.$touch()"
                            />
                            <v-text-field
                              v-model="lastName"
                              outlined
                              :error-messages="lastNameErrors"
                              label="Last Name"
                              required
                              @input="$v.lastName.$touch()"
                              @blur="$v.lastName.$touch()"
                            />
                            <v-text-field
                              v-model="company"
                              outlined
                              label="Law Firm/Company"
                            />
                            <v-text-field
                              v-model="email"
                              outlined
                              :error-messages="emailErrors"
                              label="E-mail"
                              required
                              @input="$v.email.$touch()"
                              @blur="$v.email.$touch()"
                            />
                            <v-text-field
                              :value="formatDate(picker)"
                              label="Date"
                              outlined
                              @focus="picker = null"
                            />
                            <v-menu
                              ref="menu"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="time"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="time"
                                  label="Time"
                                  append-icon="mdi-clock-time-four-outline"
                                  readonly
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <v-time-picker
                                v-if="menu2"
                                v-model="time"
                                full-width
                                format="24hr"
                                @click:minute="$refs.menu.save(time)"
                              />
                            </v-menu>
                            <v-text-field
                              v-model="phone"
                              label="Phone"
                              outlined
                            />
                            <v-textarea
                              v-model="message"
                              outlined
                              label="Message"
                              required
                              :error-messages="messageErrors"
                              @change="$v.message.$touch()"
                              @blur="$v.message.$touch()"
                            />
                          </form>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            class="mx-auto mb-4"
                            rounded
                            outlined
                            color="primary"
                            min-width="200"
                            @click="submit"
                          >
                            submit
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-card flat>
                      <v-card-text class="mt-n1">
                        <p style="font-size: 18px;">
                          Talk to our experts about anything that concerns you.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <v-card
                      class="mt-2 mb-8 mx-8"
                      elevation="15"
                    >
                      <v-card-text>
                        <form class="mt-4">
                          <v-text-field
                            v-model="firstName"
                            outlined
                            :error-messages="firstNameErrors"
                            label="First Name"
                            name="name"
                            required
                            @input="$v.firstName.$touch()"
                            @blur="$v.firstName.$touch()"
                          />
                          <v-text-field
                            v-model="lastName"
                            outlined
                            :error-messages="lastNameErrors"
                            label="Last Name"
                            required
                            @input="$v.lastName.$touch()"
                            @blur="$v.lastName.$touch()"
                          />
                          <v-text-field
                            v-model="company"
                            outlined
                            label="Law Firm/Company"
                          />
                          <v-text-field
                            v-model="email"
                            outlined
                            :error-messages="emailErrors"
                            label="E-mail"
                            name="email"
                            required
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                          />
                          <v-text-field
                            v-model="phone"
                            label="Phone"
                            name="phone"
                            outlined
                          />
                          <v-textarea
                            v-model="message"
                            outlined
                            label="Message"
                            name="message"
                            required
                            :error-messages="messageErrors"
                            @change="$v.message.$touch()"
                            @blur="$v.message.$touch()"
                          />
                        </form>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          class="mx-auto mb-4"
                          rounded
                          outlined
                          color="primary"
                          min-width="200"
                          type="submit"
                          name="submit"
                          @click="submit"
                        >
                          submit
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          color="error"
        >
          {{ 'Please fill out the required fields' }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              small
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarContactSuccess"
          :timeout="timeout"
          color="success"
        >
          {{ 'Your request was sent successfully' }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              small
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarContactError"
          :timeout="timeout"
          color="error"
        >
          {{ 'There was a problem sending your request. Please try again later or email hello@lavvira.net' }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              small
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  import axios from 'axios'

  export default {
    mixins: [validationMixin],

    validations: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      message: { required },
    },

    data: () => ({
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      email: '',
      message: '',
      snackbar: false,
      snackbarContactSuccess: false,
      snackbarContactError: false,
      timeout: 2000,
      tab: 0,
      picker: null,
      time: null,
      menu2: false,
    }),

    computed: {
      firstNameErrors () {
        const errors = []
        if (!this.$v.firstName.$dirty) return errors
        !this.$v.firstName.required && !this.$v.firstName.$model.length && errors.push('Name is required.')
        return errors
      },
      lastNameErrors () {
        const errors = []
        if (!this.$v.lastName.$dirty) return errors
        !this.$v.lastName.required && !this.$v.lastName.$model.length && errors.push('Name is required.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
      messageErrors () {
        const errors = []
        if (!this.$v.message.$dirty) return errors
        !this.$v.message.required && errors.push('Text is required.')
        return errors
      },
    },

    methods: {
      async submit () {
        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          company: this.company,
          phone: this.phone,
          email: this.email,
          message: this.message,
          picker: this.picker,
          time: this.time,
        }
        this.$v.$touch()
        if (this.$v.$anyError) {
          this.snackbar = true
        } else {
          try {
            axios.defaults.baseURL = 'http://localhost:3000'
            const { data } = await axios.post('/pagecontactservice/', payload, {
              baseURL: 'http://localhost:8082',
              withCredentials: true,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            })
            if (data.success) {
              this.snackbarContactSuccess = true
            } else {
              this.snackbarContactError = true
            }
          } catch (e) {
            console.error(e, 'ERROR')
          }
        }
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      reset () {
        this.$v.$reset()
        this.firstName = ''
        this.lastName = ''
        this.company = ''
        this.email = ''
        this.phone = ''
        this.message = ''
        this.picker = null
        this.time = null
      },
    },
  }
</script>
